import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      innerClass={"logout-success"} hideTopBar>
      <h2>Zostałeś poprawnie <br/>wylogowany</h2>
      <p>Wróć do nas jeszcze. </p>
      <div className='btn-holder'>
        <Button action={() => {
            modal.hide()
        }}>Zaloguj się ponownie</Button>
      </div>
    </Modal>
  );
});
