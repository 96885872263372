import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { getUserDetails, logout, useUserDispatch } from '../../UserContext';
import Button from '../Button';
import Exit from "./img/exit.svg"
import ErrorIcon from "../Modal/Modals/img/confirm-error.svg"
import { errorDefinitions, customActions } from "./definitions"
import _ from "lodash"
import "./style.scss"

export function showErrorPopup(data, code) {
  if(data && code) data.code = code
  if(!data) {
    data = translateError()
  }
  window.dispatchEvent(new CustomEvent("SHOW_ERROR_POPUP", { detail: data }), true);
}

export function closeErrorPopup() {
  window.dispatchEvent(new CustomEvent("CLOSE_ERROR_POPUP", {}), true);
}

export const translateError = (error) => {
  let errorDetails = {}

  if(error) {
    errorDetails = _.filter(errorDefinitions, { ident: error })[0] || _.filter(errorDefinitions, { ident: "generic" })[0]
  } else {
    errorDetails = _.filter(errorDefinitions, { ident: "generic" })[0]
  }

  return errorDetails
};

export default function ModalComponent({ modalClass = "" }) {
  const [isOpen, setOpen] = useState(false)
  const [error, setErrorDetails] = useState({})
  const dispatch = useUserDispatch()

  Modal.setAppElement('#root')

  useEffect(() => {
    window.addEventListener("SHOW_ERROR_POPUP", (data) => {
      const errorDetails = data.detail || {}
      setErrorDetails(errorDetails)
      setOpen(true)
    })

    window.addEventListener("CLOSE_ERROR_POPUP", (data) => {
      setOpen(false)
    })
  }, [])

  return (
    <Modal isOpen={isOpen} className={"error-modal " + modalClass} overlayClassName={"error-overlay " + modalClass}>
    <>
      { !error.disableClose && <div className="button-wrapper">
        <button className='close-button'>
          <img src={Exit} alt="" onClick={ () => {
            if(error.logout) {
              logout(dispatch)
            }
            setOpen(false)
          } } />
        </button>
      </div> }
      <div className="content">
        <h2 dangerouslySetInnerHTML={{ __html: error.title }}></h2>
        <img src={ ErrorIcon } alt="Error icon"/>
        { error.code && <div className="error-code">
          <h4>Kod błędu: </h4>
          <p>{ error.code }</p>
        </div> }
        <div className='error-description' dangerouslySetInnerHTML={{ __html: error.desc }}></div>
        { error.returnUrl ? <Button btnClass='orange' href={ error.returnUrl } action={ () => { setOpen(false) } }>{ error.button }</Button> : <Button btnClass='orange' action={ () => {
          if(error.updateUserData) {
            getUserDetails(dispatch)
          }
          if(error.customAction && customActions[error.customAction]) {
            customActions[error.customAction]()
          }
          if(error.logout) {
            logout(dispatch)
          }
          setOpen(false)
        } }>{ error.button }</Button> }
      </div>
    </>
  </Modal>
  )
}
