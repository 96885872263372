// import { clearUrlParams } from "../UrlParamsHelper";

import { showErrorPopup, translateError } from "../components/ErrorHandling";
import { utmParamLogin } from "../Helpers";
import { addUrlParam, getUrlParam, initUrlParams, removeUrlParam } from "../UrlParamsHelper";

export async function getAccountDetails(dispatch) {
  try {
    dispatch({ type: 'ACCOUNT_DETAILS' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/alterzone/consumer/stats", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            }
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      const data = {
        value: res.data.balance,
        history: res.data.historyByMonth,
        referral: res.data.referral,
        voucher: res.data.voucher
      }

      dispatch({ type: 'ACCOUNT_DETAILS_SUCCESS', payload: data });
      sessionStorage.setItem('accountDetails', JSON.stringify(data));
      return data
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'ACCOUNT_DETAILS_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'ACCOUNT_DETAILS_ERROR', error: error });
  }
}

export async function getUserDetails(dispatch) {
  try {
    dispatch({ type: 'USER_DETAILS' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/details", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            }
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'USER_DETAILS_SUCCESS', payload: res.data });
      if(res.data?.consumer?.consentAlterzoneParticipate) {
        getAccountDetails(dispatch)
      }
      sessionStorage.setItem('currentUser', JSON.stringify(res.data));
      return res.data
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'USER_DETAILS_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'USER_DETAILS_ERROR', error: error });
  }
}

export async function confirm(dispatch) {
  try {
    dispatch({ type: 'CONFIRM' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/confirm", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: "Bearer " + getUrlParam("tempToken")
            }
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'CONFIRM_SUCCESS', payload: res.data });
      return res
    } else if(res.data.error) {
      return res
    }
 
    dispatch({ type: 'CONFIRM_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'CONFIRM_ERROR', error: error });
  }
}

export async function register(dispatch, payload) {
  try {
    dispatch({ type: 'REGISTER' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/register", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'REGISTER_SUCCESS', payload: res.data });
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'REGISTER_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'REGISTER_ERROR', error: error });
  }
}

export async function login(dispatch, payload) {
  try {
    dispatch({ type: 'LOGIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/login", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
      sessionStorage.setItem('token', JSON.stringify(res.data.token));
      if(localStorage.getItem("rememberMe")) {
        localStorage.setItem("refreshToken", JSON.stringify(res.data.token))
      }
      getUserDetails(dispatch)
      utmParamLogin()
      return res.data
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'LOGIN_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function remind(dispatch, payload) {
  try {
    dispatch({ type: 'REMIND' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/request-reset-password", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'REMIND_SUCCESS', payload: res.data });
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'REMIND_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'REMIND_ERROR', error: error });
  }
}

export async function reset(dispatch, payload, token) {
  try {
    dispatch({ type: 'RESET' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/reset-password", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + token || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'RESET_SUCCESS', payload: res.data });
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'RESET_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'RESET_ERROR', error: error });
  }
}

export async function joinOtp(dispatch, payload) {
  try {
    dispatch({ type: 'JOIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/referral/join-otp", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'JOIN_SUCCESS', payload: res.data });
      return res
    } else if (res.data.error === "error_please_wait") {
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'JOIN_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'JOIN_ERROR', error: error });
  }
}

export async function join(dispatch, payload) {
  try {
    dispatch({ type: 'JOIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/referral/join", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'JOIN_SUCCESS', payload: res.data });
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'JOIN_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'JOIN_ERROR', error: error });
  }
}

export async function voucherOtp(dispatch, payload) {
  try {
    dispatch({ type: 'VOUCHER_OTP' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/voucher/otp", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'VOUCHER_OTP_SUCCESS', payload: res.data });
      return res
    } else if (res.data.error === "error_please_wait") {
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'VOUCHER_OTP_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'VOUCHER_OTP_ERROR', error: error });
  }
}

export async function voucherObtain(dispatch, payload) {
  try {
    dispatch({ type: 'VOUCHER_OBTAIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/voucher/obtain", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'VOUCHER_OBTAIN_SUCCESS', payload: res.data });
      return res
    } else if (res.data.error === "error_m2_exception" && res.data.details === "Coupon creation falied at CHIC") {
      showErrorPopup(translateError("coupon_creation_failed_at_chic"), res.meta.ts)
    } else if (res.data.error === "error_please_wait" || res.data.error === "error_mobile_phone_taken") {
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'VOUCHER_OBTAIN_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'VOUCHER_OBTAIN_ERROR', error: error });
  }
}

export async function loginByToken(dispatch, token) {
  try {
    const payload = {
      withToken: true
    }

    dispatch({ type: 'LOGIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/details", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      res.data.token = JSON.parse(sessionStorage.getItem("token")) || {}
      dispatch({ type: 'USER_DETAILS_TOKEN_SUCCESS', payload: res.data });
      // getUserDetails(dispatch)
      sessionStorage.setItem('currentUser', JSON.stringify(res.data));
      initUrlParams()
      removeUrlParam("token")
      removeUrlParam("tempToken")
      return res.data
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'LOGIN_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function refreshToken(dispatch, token) {
  try {
    dispatch({ type: 'LOGIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/token-refresh", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(token)?.uuid,
            },
            body: null
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'REFRESH_SUCCESS', payload: res.data });
      sessionStorage.setItem('token', JSON.stringify(res.data.token));
      loginByToken(dispatch, res.data.token.uuid)
      return res.data
    } else {
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("rememberMe")
    }
 
    dispatch({ type: 'REFRESH_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'REFRESH_ERROR', error: error });
  }
}

export async function logout(dispatch, payload) {
  try {
    dispatch({ type: 'LOGOUT' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/logout", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'LOGOUT_SUCCESS', payload: res.data });
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('accountDetails');
      sessionStorage.removeItem('token');
      localStorage.removeItem("rememberMe")
      localStorage.removeItem("token")
      removeUrlParam("token")
      // clearUrlParams()
      return res.data
    } else {
      // showErrorPopup(translateError(res.data.error), res.meta.ts)
    }

    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('accountDetails');
    sessionStorage.removeItem('token');
    removeUrlParam("token")
    // clearUrlParams()
    dispatch({ type: 'LOGOUT_ERROR', error: res.data.error });
    return;
  } catch (error) {
    // showErrorPopup(translateError("generic"))
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('accountDetails');
    sessionStorage.removeItem('token');
    removeUrlParam("token")
    // clearUrlParams()
    dispatch({ type: 'LOGOUT_ERROR', error: error });
  }
}

export async function balance(dispatch, payload) {
  try {
    dispatch({ type: 'BALANCE' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/referral/balance-history", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'BALANCE_SUCCESS', payload: res.data });
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'BALANCE_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'BALANCE_ERROR', error: error });
  }
}

export async function payout(dispatch, payload) {
  try {
    dispatch({ type: 'PAYOUT' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/referral/payment-create", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'PAYOUT_SUCCESS', payload: res.data });
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'PAYOUT_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'PAYOUT_ERROR', error: error });
  }
}

export async function stats(dispatch, payload) {
  try {
    dispatch({ type: 'STATS' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/referral/stats", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'STATS_SUCCESS', payload: res.data });
      return res
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'STATS_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'STATS_ERROR', error: error });
  }
}