import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import Success from "./img/confirm-success.svg"

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      innerClass={"register-success"} hideTopBar>
      <h2>Potwierdź adres <span>e-mail</span></h2>
      <img src={Success} alt=""/>
      <p>Twoje konto zostało utworzone, ale nie jest jeszcze aktywne. <br/><strong>Teraz wystarczy, że zajrzysz do wiadomości, którą wysłaliśmy na Twój adres e-mail i aktywujesz konto.</strong></p>
      <p className="small"><strong>Nie widzisz maila?</strong> <br/>Sprawdź folder SPAM lub skontaktuj się z nami <br/>pod adresem: kontakt@zyscash.pl</p>
      <div className='btn-holder'>
        <Button action={() => {
            modal.hide()
        }}>OK</Button>
      </div>
    </Modal>
  );
});
