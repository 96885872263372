import React from "react"
import { Link, NavLink } from 'react-router-dom'
import "./style.scss"
import Logo from "../../../img/logo-white.svg"
import { ReactComponent as Mail } from "./img/mail.svg"
import { ReactComponent as FB } from "./img/facebook.svg"
import { ReactComponent as Insta } from "./img/instagram.svg"
import BatLogo from "./img/bat-logo.svg"
import NiceModal from "@ebay/nice-modal-react"
import { getMainTermUrl } from "../../content/terms"

// import AgeMobile from "./img/consents/age-verified-mobile.svg"
// import AgeDesktop from "./img/consents/age-verified-desktop.svg"
// import AgeLaptop from "./img/consents/age-verified-laptop.svg"
// import CopyMobile from "./img/consents/copyrights-mobile.svg"
// import CopyDesktop from "./img/consents/copyrights-desktop.svg"
// import CreatedMobile from "./img/consents/created-by-mobile.svg"
// import CreatedLaptop from "./img/consents/created-by-laptop.svg"
// import CreatedDesktop from "./img/consents/created-by-desktop.svg"

export default function Footer({ logged }) {

  return (
    <footer className={logged ? "logged" : ""}>
      <div className="footer-top">
        <div className="over-18">
          <img src={Logo} alt="" className="logo" />
          <p>Strona przeznaczona dla pełnoletnich konsumentów wyrobów nikotynowych <br/>BAT Polska Trading sp. z o.o. oraz wyrobów powiązanych. </p>
        </div>
        <hr className="line" />
        <div className="footer-left">
          <div className="footer-contact">
            <div className="social">
              <h4>Tu nas znajdziesz:</h4>
              <div className="wrapper">
                <div><a href="https://www.facebook.com/profile.php?id=100066650114831" target="_blank" rel="noopener noreferrer"><FB /></a></div>
                <div><a href="https://www.instagram.com/glo_polska/" target="_blank" rel="noopener noreferrer"><Insta /></a></div>
                <div><a href="mailto:kontakt@zyscash.pl" target="_blank" rel="noopener noreferrer"><Mail /></a></div>
                <div><a href="mailto:kontakt@zyscash.pl" rel="noopener noreferrer" className="email-link"><h4 className="email">kontakt@zyscash.pl</h4></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-nav">
        <ul>
          <li><Link to="/regulaminy">Regulaminy</Link></li>
          {logged && <li><NavLink to={logged ? "/faq" : ""}>FAQ</NavLink></li>}
          <li><a href="/assets/polityka-prywatnosci.pdf" target="_blank" rel="noopener noreferrer">Polityka prywatności</a></li>
          <li><span onClick={() => NiceModal.show("cookie-options")}>Preferencje Cookies</span></li>
          <li><a href="/assets/polityka-cookies.pdf" target="_blank" rel="noopener noreferrer">Polityka Cookies</a></li>
        </ul>
        <div className="flex-bat-logo desktop">
          <img src={BatLogo} alt="" className="bat-logo"/>
          <p className="copyrights">© 2022 BAT Polska Trading. All rights reserved</p>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="flex-bat-logo mobile">
          <img src={BatLogo} alt="" />
          <p className="copyrights">© 2022 BAT Polska Trading. All rights reserved</p>
        </div>
        <p>Serwis został stworzony przez BAT Polska Trading Sp. z o.o. z siedzibą w Warszawie. Strona przeznaczona jest wyłącznie dla osób pełnoletnich zainteresowanych ofertą wyrobów nikotynowych BAT Polska Trading sp. z o.o.</p>
      </div>
    </footer>
  );
}
