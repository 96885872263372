// Get url params, save it to sessionStorage and then (optionally) remove from url without page reload. Works only when window.location.search is not empty.
// Need to be called before any URL changes!
export function initUrlParams(remove) {
  let location = window.location.search.replace("?", "");
  if (location) {
    let params = location.split("&");
    let parsedParams = {};
    params.forEach((item) => {
      item = item.split("=");
      if (item[0]) parsedParams[item[0]] = item[1] || null;
    });
    sessionStorage.setItem("urlParams", JSON.stringify(parsedParams));
    if(remove) window.history.replaceState({}, document.title, document.location.pathname);
  }
}
// window.initUrlParams = initUrlParams;

// Get all URL params from sessionStorage.
export function getAllUrlParams() {
  const params = JSON.parse(sessionStorage.getItem("urlParams")) || {};
  return params;
}
// window.getAllUrlParams = getAllUrlParams;

// Get only selected URL params. Eg. getSelectedUrlParams(['utm_source', 'utm_campaign']).
export function getSelectedUrlParams(list) {
  const params = JSON.parse(sessionStorage.getItem("urlParams")) || {};
  let selectedParams = {};
  list.forEach((element) => {
    if (params[element] || params[element] === null)
      selectedParams[element] = params[element] || null;
  });
  return selectedParams;
}
// window.getSelectedUrlParams = getSelectedUrlParams;

// Return value of only one URL param. If not exist returns null.
export function getUrlParam(name) {
  const params = JSON.parse(sessionStorage.getItem("urlParams")) || {};
  return (params[name] && decodeURIComponent(params[name])) || null
}
// window.getUrlParam = getUrlParam;

// Add new param to saved in sessionStorage URL params.
export function addUrlParam(name, value) {
  let params = JSON.parse(sessionStorage.getItem("urlParams")) || {};
  params[name] = value || null;
  sessionStorage.setItem("urlParams", JSON.stringify(params));
  return params;
}
// window.addUrlParam = addUrlParam;

// Remove param from saved in sessionStorage URL params.
export function removeUrlParam(name) {
  let params = JSON.parse(sessionStorage.getItem("urlParams")) || {};
  delete params[name];
  sessionStorage.setItem("urlParams", JSON.stringify(params));
  return params;
}
// window.removeUrlParam = removeUrlParam;

// Clear all URL params stored in sessionStorage.
export function clearUrlParams() {
  sessionStorage.setItem("urlParams", JSON.stringify({}));
  return {};
}
// window.clearUrlParams = clearUrlParams;

// Returns parsed URL params from sessionStorage to string like: "?utm_source=s&utm_campaign=g".
// Returns all params if list is empty, or only selected URL params. Eg. parseUrlParams(['utm_source', 'utm_campaign']).
// Ready to concat with any redirect url.
export function parseUrlParams(list) {
  let params = JSON.parse(sessionStorage.getItem("urlParams")) || {};
  let url = "";
  if (list && list.length > 0) {
    let selectedParams = {};
    list.forEach((element) => {
      if (params[element] || params[element] === null)
        selectedParams[element] = params[element] || null;
    });
    url = "?" + new URLSearchParams(selectedParams);
  } else {
    url = "?" + new URLSearchParams(params);
  }
  return url;
}
// window.parseUrlParams = parseUrlParams;