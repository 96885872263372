import React, { useState } from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';

// import AgeGateCopyMobile from "./img/consents/age-gate-mobile.svg"
// import AgeGateCopyDesktop from "./img/consents/age-gate-desktop.svg"
// import AgeGateCreatedMobile from "./img/consents/age-gate-created-mobile.svg"
// import AgeGateCreatedDesktop from "./img/consents/age-gate-created-desktop.svg"

export default NiceModal.create((props) => {
  const modal = useModal();
  const [error, setError] = useState(false)

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      overlayClass="age-gate-overlay"
      innerClass={"age-gate"} hideTopBar hideCloseButton={true}>
      {/* <picture>
        <source
          media="(min-width: 720px)"
          srcSet={AgeGateCopyDesktop}
        />

        <img
          srcSet={AgeGateCopyMobile}
          alt="legal"
        />
      </picture> */}
      <h4>Witaj na stronie dla pełnoletnich osób zainteresowanych <br/>ofertą BAT Polska Trading sp. z o.o.</h4>
      <h2>Potwierdzam, <br />że mam ukończone 18 lat</h2>
      <div className='btn-holder'>
        <Button action={() => {
          sessionStorage.setItem("over18", true)
          props.setAgeGate(false)
          modal.hide()
        }}>Tak, potwierdzam</Button>
        <Button btnClass='red-transparent' action={() => {
          setError(true)
        }}>Nie</Button>
      </div>
      {error && <h3> Musisz mieć ukończone 18 lat aby wejść na stronie</h3>}
      <p className='disclaimer'>Ta strona należy do BAT Polska Trading Sp. z o.o. Dedykowana jest dla pełnoletnich użytkowników wyrobów nikotynowych BAT Polska Trading sp. z o.o. Wszystkie wyroby należące do BAT Polska szkodzą zdrowiu i są uzależniające.</p>
      {/* <picture>
        <source
          media="(min-width: 720px)"
          srcSet={AgeGateCreatedDesktop}
        />

        <img
          srcSet={AgeGateCreatedMobile}
          alt="legal"
        />
      </picture> */}
    </Modal>
  );
});
