import React, { useEffect, useState } from "react";
import "./components/Modal/modals"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Footer from "./components/Footer";
import LogoutWarning from "./components/LogoutWarning";
import NotFound from "./components/NotFound";
import { getUrlParam, initUrlParams } from "./UrlParamsHelper";
import { getUserDetails, loginByToken, useUserDispatch, useUserState } from "./UserContext";
import ErrorHandling from "./components/ErrorHandling"
import NiceModal from "@ebay/nice-modal-react";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".app").scrollTo(0, 0);
    }, 250)
  }, [pathname]);

  return null;
}

function Transition() {
  let location = useLocation();
  const dispatch = useUserDispatch()
  const { token } = useUserState()
  const [ageGate, setAgeGate] = useState(!sessionStorage.getItem("over18") ? true : false)

  const excludedPages = () => {
    // pages without header, footer, and HW
    const exclude = [
      "/login",
      "/login/reset",
      "/rejestracja",
      "/haslo",
      "/haslo/ustaw",
      "/wylogowano",
      "/inne-oferty",
      "/twoj-rabat"
    ]

    if(exclude.includes(location.pathname)) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if(getUrlParam("token") && !Boolean(token?.uuid) && !location.pathname.includes("/haslo")) {
      loginByToken(dispatch, getUrlParam("token"))
    }

    if(token?.uuid) {
      getUserDetails(dispatch, token?.uuid)
    }
    
    initUrlParams()

    window.addEventListener("SW_READY", (e) => { document.querySelector(".loading-screen").classList.add("hide") })
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent("SW_READY", {}), true)
      window.loaderC.hide();
    }, 3500)

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(ageGate) {
      NiceModal.show("age-gate", { setAgeGate: setAgeGate })
    } else if(!localStorage.getItem("cookieOptions")) {
      NiceModal.show("cookie-options")
    } else {
      const options = JSON.parse(localStorage.getItem("cookieOptions"))
      if(!options.performance || !options.communication) {
        NiceModal.show("cookie-options")
      }
    }
  }, [ageGate])

  useEffect(() => {
    if(token.uuid) {
      NiceModal.show("program-finished")
    }
  }, [token.uuid])

  return (
      <div className="app">
        <LogoutWarning/>
        <div className="page-holder">
        <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="page"
              className="page-with-bg"
              timeout={{ enter: 250, exit: 250 }}
            >
              <div>
                <Routes location={location}>
                  <Route path={`${process.env.PUBLIC_URL}/`} exact element={<NotFound/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/404`} exact element={<NotFound/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<NotFound/>}/>
                </Routes>
                <Footer logged={token?.uuid}/>
              </div>
            </CSSTransition>
          </TransitionGroup>
          <ErrorHandling/>
        </div>
      </div>
  );
}

export default function MainView() {
  return (
    <Router>
      <ScrollToTop />
      <NiceModal.Provider>
        <Transition />
      </NiceModal.Provider>
    </Router>
  );
}
