import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      innerClass={"reset-password"} hideTopBar>
      <p className='disclaimer'>Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez z&nbsp;British American Tobacco Polska Trading sp. z&nbsp;o.o. z&nbsp;siedzibą w&nbsp;Warszawie oraz CHIC sp. z&nbsp;o.o. z&nbsp;siedzibą w Ostrzeszowie, jako współadministratorów, w&nbsp;celu prowadzenia przez te podmioty niepublicznych działań o&nbsp;charakterze marketingu bezpośredniego, obejmujących prezentowanie mi informacji o&nbsp;produktach, usługach, konkursach i&nbsp;innych inicjatywach grupy British American Tobacco:</p>
      <ul>
        <li>za pośrednictwem środków komunikacji elektronicznej (poczta elektroniczna oraz wiadomości tekstowe SMS)</li>
        <li>poprzez kontakt telefoniczny, w tym połączenia głosowe</li>
        <li>w sekcjach reklamowych na portalach społecznościowych (np. Facebook, Instagram i innych podobnych)</li>
      </ul>
      <div className='btn-holder'>
        <Button action={() => {
            modal.hide()
        }}>Zamknij</Button>
      </div>
    </Modal>
  );
});
