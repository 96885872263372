import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import Error from "./img/confirm-error.svg"

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      innerClass={"confirm-error"} hideTopBar>
      <h2>Twój link aktywacyjny stracił ważność.</h2>
      <img src={Error} alt=""/>
      <p>Wyślij nową wiadomość e-mail i spróbuj ponownie.</p>
      <div className='btn-holder'>
        <Button action={() => {
            modal.hide()
        }}>Wyślij nowy link</Button>
      </div>
    </Modal>
  );
});
