export const errorDefinitions = [
  {
    ident: "error_disabled",
    title: "UPS",
    desc: "Coś poszło nie tak.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "coupon_creation_failed_at_chic",
    title: "Wystąpił problem przy generowaniu kuponu, spróbuj ponownie.",
    desc: "Jeżeli problem powtórzył się, prosimy skontaktuj się z&nbsp;nami podając poniższy kod błędu.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_billon_maintenance",
    title: "Błąd wypłaty billon",
    desc: "Informujemy, że w dniach od 29 stycznia do 1 lutego funkcja wypłat środków pieniężnych jest niedostępna. <br/>Wypłat będzie można ponownie dokonywać od 2 lutego. <br/>Za wszelkie niedogodności przepraszamy.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_critical_database",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_critical_exception",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_message_ident_not_found",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_empty_recipient_data",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_provider_not_found",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_http_exception",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_exception",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_empty_field",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_route_not_found",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_method_not_allowed",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_unauthorized",
    title: "Wylogowano lub sesja wygasła",
    desc: "Przejdź do strony logowania.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_missing_required",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_bad_value_of_required",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_uri_not_authorized",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_wrong_token_purpose",
    title: "Wystąpił błąd",
    desc: "Link wygasł lub jest nieprawidłowy.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_consumer_not_found",
    title: "Podany e-mail lub hasło są nieprawidłowe",
    desc: "Upewnij się, że poprawnie wpisujesz dane. Spróbuj zalogować się raz jeszcze.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_email_address_taken",
    title: "Ten adres jest zajęty",
    desc: "Zaloguj się",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_reset_token_daily_limit_reached",
    title: "Przekroczono liczbę prób rejestracji",
    desc: "Spróbuj ponownie za jakiś czas.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_consumer_already_joined",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "ok",
    returnUrl: null,
    disableClose: true,
    updateUserData: false,
    logout: true
  },
  {
    ident: "error_mobile_phone_taken",
    title: "Numer telefonu jest nieprawidłowy",
    desc: "Podany numer telefonu istnieje już w&nbsp;bazie użytkowników.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_please_wait",
    title: "Proszę czekać",
    desc: "Wiadomość została wysłana, jeżeli jej nie otrzymałeś spróbuj ponownie za chwilę.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_too_low_balance_value",
    title: "To dość niespodziewane",
    desc: "Wystąpił błąd, zaloguj się ponownie.",
    button: "ok",
    returnUrl: null,
    disableClose: true,
    updateUserData: false,
    logout: true
  },
  {
    ident: "error_billon_over_budget",
    title: "To dość niespodziewane",
    desc: "Wystąpił błąd komunikacji z&nbsp;systemem wypłat, prosimy spróbuj ponownie później.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_referral_code_invalid",
    title: "Wystąpił błąd",
    desc: "Upewnij się, że link od polecającego jest poprawny.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_wrong_otp",
    title: "Błędny kod SMS",
    desc: "Upewnij się, że poprawnie wpisujesz dane.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_expired_otp",
    title: "Kod SMS wygasł",
    desc: "Zacznij jeszcze raz.",
    button: "ok",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_expired",
    title: "Wylogowano lub sesja wygasła",
    desc: "Przejdź do strony logowania.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "generic",
    title: "To dość niespodziewane",
    desc: "Coś poszło nie tak. Spróbuj raz jeszcze.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  }
]

export const customActions = {
}