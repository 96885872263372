import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      innerClass={"reset-password"} hideTopBar>
      <h2>Właśnie wysłaliśmy do Ciebie <br />mail z linkiem do resetu hasła</h2>
      <p>Kliknij w niego  i ustaw nowe hasło.<br />Link ważny jest przez 30 minut.</p>
      <div className='btn-holder'>
        <Button action={() => {
            modal.hide()
        }}>Zamknij</Button>
      </div>
    </Modal>
  );
});
