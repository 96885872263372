import React, { useState } from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useWindowSize } from '../../../Helpers';

export default NiceModal.create(() => {
  const { width } = useWindowSize()
  const SCREEN_HD = 1280
  const modal = useModal();
  const [page, setPage] = useState("technical")
  const [showMain, setShowMain] = useState(true)
  const [cookieOptions, setCookieOptions] = useState(JSON.parse(localStorage.getItem("cookieOptions")) || {
    technical: true,
    performance: false,
    communication: false
  })

  const pages = {
    technical: {
      title: <h3><span>Techniczne</span> pliki&nbsp;cookies</h3>,
      desc: <p>Są to pliki niezbędne do prawidłowego funkcjonowania strony. Techniczne pliki cookies, pamiętają o tym kim jesteś, jakie masz preferencje (język, ustawienia regionalne), dzięki czemu możesz w&nbsp;wygodny sposób korzystać ze strony i&nbsp;jej ustawień. Nie możesz z&nbsp;nich zrezygnować, ale możesz ustawić w&nbsp;preferencjach przeglądarki odrzucanie wszystkich cookies. Może to jednak spowodować, że strona nie będzie wyświetlana prawidłowo albo niektóre funkcje nie będą działać.</p>,
      button: <Button action={() => setPage("performance")}>Dalej</Button>
    },
    performance: {
      title: <h3><span>Wydajnościowe</span> pliki&nbsp;cookies</h3>,
      desc: <p>Używając tych cookies zbieramy wyłącznie anonimowe informacje. Dzięki nim ustalamy, jak użytkownicy korzystają z&nbsp;naszej strony i&nbsp;dowiadujemy się, czy nie ma w niej błędów i&nbsp;możemy poprawić jej wydajność.</p>,
      button: <Button action={() => setPage("communication")}>Dalej</Button>
    },
    communication: {
      title: <h3><span>Marketingowe</span> pliki&nbsp;cookies</h3>,
      desc: <p>Te pliki cookies (i&nbsp;inne technologie do nich podobne) pozwalają śledzić historię Twojego przeglądania na tej stronie oraz na innych stronach, co pozwala nam przekazywać ukierunkowane informacje do odbiorcy. Na ich podstawie możemy na przykład przestawiać Ci informacje o&nbsp;charakterze promocyjnym.</p>,
      button: <Button action={() => {closeAndSave()}}>Zapisz</Button>
    }
  }

  const switchValue = (name) => {
    let options = {...cookieOptions}
    options[name] = !options[name]
    setCookieOptions(options)
  }

  const setAll = () => {
    let options = {
      technical: true,
      performance: true,
      communication: true
    }

    setCookieOptions(options)

    localStorage.setItem("cookieOptions", JSON.stringify(options))

    if (cookieOptions.communication && window.dataLayer) {
      window.dataLayer.push({
        event: "GTMcookieOK",
      });
    }
    consentGranted(consentGrantedUpdate)
    modal.hide()
  }

  const closeAndSave = () => {
    localStorage.setItem("cookieOptions", JSON.stringify(cookieOptions))

    if (cookieOptions.communication && window.dataLayer) {
      window.dataLayer.push({
        event: "GTMcookieOK",
      });
    }
    
    consentGranted(consentGrantedUpdate)
    modal.hide()
  }

  function consentGranted(callback) {
    var consentTimer = setInterval(function(){
    var consent = JSON.parse(window.localStorage.getItem('cookieOptions'))
    console.log(consent)
      if(consent){
        clearInterval(consentTimer);
        callback(consent);
      }
    }, 100);
  }

  function consentGrantedUpdate(consent) {
    window.gtag('consent', 'update', {
        'ad_storage': window.zgoda(consent.communication),
        'analytics_storage': window.zgoda(consent.performance),
        'functionality_storage': window.zgoda(consent.technical)
    });
    window.dataLayer.push({'event':'consetUpdate'})
  };


  return (
    <Modal isOpen={modal.visible} onClose={() => closeAndSave()}
      onAfterClose={() => modal.remove()}
      hideCloseButton={true}
      innerClass={"cookie-options"} hideTopBar>
      { showMain ? <>
        <div className='cookie-main'>
          <p>Na tej stronie korzystamy z plików cookie (i innych podobnych technologii), aby zapewnić jej prawidłowe działanie, ulepszać ją oraz analizować zachowania użytkowników i na tej podstawie oferować spersonalizowaną komunikację marketingową.</p>
          <p>Klikając “Akceptuję wszystkie”, zgadzasz się abyśmy korzystali ze wszystkich ww. rodzajów cookies. Aby samemu zdecydować, które rodzaje cookies akceptujesz, wybierz “Więcej opcji”. Możesz zmienić te ustawienia w dowolnym momencie, klikając w link widoczny w stopce.</p>
          <p>Więcej informacji w <a href="/assets/polityka-cookies.pdf" target="_blank" rel="noopener noreferrer">Polityce cookies</a></p>
          <div className='buttons'>
            <Button btnClass='red' action={() => { setAll();}}>Akceptuj wszystkie</Button>
            <Button btnClass='red-transparent' action={() => { setShowMain(false) }}>Więcej opcji</Button>
          </div>
        </div>
      </> : <>
        <div className='cookies-top'>
          { width < SCREEN_HD && <h2>Cookies</h2> }
          <ul className='cookies-nav'>
            <li className={ page === "technical" ? "active" : "" } onClick={() => setPage("technical")}>Techniczne</li>
            <li className={ page === "performance" ? "active" : "" } onClick={() => setPage("performance")}>Wydajnościowe</li>
            <li className={ page === "communication" ? "active" : "" } onClick={() => setPage("communication")}>Śledzenie i komunikacja</li>
          </ul>
        </div>
        <div className='cookies-content'>
          { pages[page].title }
          { pages[page].desc }
          <div className='switch-outer' key={page} onClick={() => { if(page !== "technical") switchValue(page) }}>
            <div className={cookieOptions[page] ? "switch active" : "switch"}></div>
            { page === "technical" ? <p>Zawsze aktywne</p> : <p>{cookieOptions[page] ? "Zaakceptowane" : "Niezaakceptowane"}</p> }
          </div>
          { pages[page].button }
        </div>
      </> }
    </Modal>
  );
});
