import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import "./style.scss"

export default function NotFound () {

  return (
    <div className="not-found">
      <div className="not-found-inner">
        <h1>404</h1>
        <div className="text">
          <h2>Program ZysCash <br/><span>został zamknięty</span></h2>
          {/* <p>To była niezwykła podróż, <br/>pełna korzyści i&nbsp;możliwości.<br/><strong>Dzięki za obecność <br/>i&nbsp;zaangażowanie.</strong></p> */}
        </div>
      </div>
    </div>
  )
}
