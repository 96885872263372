let user = sessionStorage.getItem("currentUser") ? JSON.parse(sessionStorage.getItem("currentUser")) : {};
let account = sessionStorage.getItem("accountDetails") ? JSON.parse(sessionStorage.getItem("accountDetails")) : {};
let token = sessionStorage.getItem("token") ? JSON.parse(sessionStorage.getItem("token")) : {};
 
export const initialState = {
  userDetails: user || {},
  accountDetails: account || {},
  token: token || {},
  loading: false,
  errorMessage: null
};
 
export const UserReducer = (initialState, action) => {
  switch (action.type) {
    case "REGISTER":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "REGISTER_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
 
    case "REGISTER_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "LOGIN":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        token: action.payload.token,
        loading: false,
        errorMessage: null
      };
 
    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "REMIND":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "REMIND_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
  
    case "REMIND_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "RESET":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "RESET_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
  
    case "RESET_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "CONFIRM":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "CONFIRM_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
  
    case "CONFIRM_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "JOIN":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "JOIN_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
  
    case "JOIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "PAYOUT":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "PAYOUT_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
  
    case "PAYOUT_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "VOUCHER_OTP":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "VOUCHER_OTP_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
  
    case "VOUCHER_OTP_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "VOUCHER_OBTAIN":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "VOUCHER_OBTAIN_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null
      };
  
    case "VOUCHER_OBTAIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "REFRESH":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "REFRESH_SUCCESS":
      return {
        ...initialState,
        token: action.payload.token,
        loading: false,
        errorMessage: null
      };
 
    case "REFRESH_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "USER_DETAILS":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "USER_DETAILS_SUCCESS":
      return {
        ...initialState,
        userDetails: action.payload,
        loading: false,
        errorMessage: null
      };

    case "USER_DETAILS_TOKEN_SUCCESS":
      return {
        ...initialState,
        userDetails: action.payload,
        token: action.payload.token,
        loading: false,
        errorMessage: null
      };
  
    case "USER_DETAILS_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "ACCOUNT_DETAILS":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "ACCOUNT_DETAILS_SUCCESS":
      return {
        ...initialState,
        accountDetails: action.payload,
        loading: false,
        errorMessage: null
      };
  
    case "ACCOUNT_DETAILS_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "BALANCE":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };

    case "BALANCE_SUCCESS":
      return {
        ...initialState,
        userBalance: action.payload,
        loading: false,
        errorMessage: null
      };
    
    case "STATS":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };

    case "STATS_SUCCESS":
      return {
        ...initialState,
        userStats: action.payload,
        loading: false,
        errorMessage: null
      };

    case "LOGOUT":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };

    case "LOGOUT_SUCCESS":
      return {
        ...initialState,
        loading: false,
        userDetails: {},
        token: {},
        accountDetails: {},
        errorMessage: null
      };

    case "LOGOUT_ERROR":
      return {
        ...initialState,
        loading: false,
        userDetails: {},
        token: {},
        accountDetails: {},
        errorMessage: action.error
      };
 
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};