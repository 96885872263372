import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Close from "./img/close.svg"

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      overlayClass="program-finished-overlay"
      innerClass={"program-finished"} hideTopBar hideCloseButton={true}>
      <div className='top'>
        <div className='close-modal' onClick={() => modal.hide()}><img src={Close} alt="Close"/></div>
        <h3>
          <span className='bold'>Dziękujemy,</span><br/>
          <span>że byliście z&nbsp;nami</span>
        </h3>
      </div>
      <div className='content'>
        <h3><span>Program ZysCash zostaje</span> zamknięty z&nbsp;dniem 14.08.2023</h3>
        <ul>
          <li><span>1</span>Do 14 sierpnia 2023 roku można nadal polecać urządzenia glo™ i&nbsp;zdobywać nagrody.</li>
          <li><span>2</span>14 sierpnia 2023 r. możliwość dalszego polecania zostanie zakończona, a&nbsp;kody realizowane po tym dniu nie będą przynosiły korzyści w&nbsp;postaci nagrody (30zł za skuteczne polecenie).</li>
          <li><span>3</span>Od 14 sierpnia do 05 września 2023 r. nadal będziesz mieć dostęp do swojego konta, salda, historii poleceń i&nbsp;wypłat.</li>
          <li><span>4</span>Ostatnim dniem, kiedy można dokonać wypłaty, będzie 05 września 2023 roku.</li>
          <li><span>5</span>Więcej szczegółów znajdziesz w wiadomości e-mail którą do Ciebie wysłaliśmy.</li>
        </ul>
      </div>
    </Modal>
  );
});
