import NiceModal from '@ebay/nice-modal-react';
import ResetPassword from './Modals/ResetPassword';
import LogoutSuccess from './Modals/LogoutSuccess';
import RegisterSuccess from './Modals/RegisterSuccess';
import ConfirmSuccess from './Modals/ConfirmSuccess';
import ConfirmError from './Modals/ConfirmError';
import AgeGate from './Modals/AgeGate';
import CookieOptions from './Modals/CookieOptions';
import ResetPasswordSuccess from './Modals/ResetPasswordSuccess';
import PayoutSuccess from './Modals/PayoutSuccess';
import OldConsent from './Modals/OldConsent';
import ThisIsTheEnd from './Modals/ThisIsTheEnd';

NiceModal.register('reset-password', ResetPassword);
NiceModal.register('reset-password-success', ResetPasswordSuccess);
NiceModal.register('logout-success', LogoutSuccess);
NiceModal.register('register-success', RegisterSuccess);
NiceModal.register('confirm-success', ConfirmSuccess);
NiceModal.register('confirm-error', ConfirmError);
NiceModal.register('age-gate', AgeGate);
NiceModal.register('cookie-options', CookieOptions);
NiceModal.register('payout-success', PayoutSuccess);
NiceModal.register('old-consent', OldConsent);
NiceModal.register('program-finished', ThisIsTheEnd);