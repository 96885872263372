import React from 'react'
import Modal from "../index"
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';

export default NiceModal.create(() => {
  const modal = useModal();
  const navigate = useNavigate()

  return (
    <Modal isOpen={modal.visible} onClose={() => modal.hide()}
      onAfterClose={() => modal.remove()}
      innerClass={"confirm-success"} hideTopBar>
      <h2>Udało się! <br/>Konto zostało aktywowane!</h2>
      <p>Możesz teraz się zalogować i&nbsp;zacząć korzystać z&nbsp;ZysCash.</p>
      <div className='btn-holder'>
        <Button action={() => {
            modal.hide();
            navigate("/", { replace: true })
        }}>Wpuść mnie</Button>
      </div>
    </Modal>
  );
});
